<template>
  <div> 
    <v-row class="d-flex justify-space-around py-5">
      <v-card 
      width="85%"
      outlined
      elevation="4"
      >
        <v-card-title>
          {{ $t('datetime_page.title') }}
          <v-spacer></v-spacer>
          <v-icon
          v-if="isAdministrator && isLicenseValid"
          small
          class="px-1"
          @click="editTimeSettings"
          >mdi-pencil</v-icon>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col xl="2" lg="2" md="3" sm="5">
              {{ $t('datetime_page.current_system_time') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="actualTime" />
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="2" lg="2" md="3" sm="5">
              {{ $t('datetime_page.timezone') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="lightmasterTimezone" />
            </v-col>
          </v-row>
          <v-row>
            <v-col xl="2" lg="2" md="3" sm="5">
              {{ $t('datetime_page.system_timemode') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="timeMode == 'ntp' ? $t('datetime_page.radio_ntp_sync') : $t('datetime_page.radio_manual')" />
            </v-col>
          </v-row>
          <v-row v-if="timeMode == 'ntp'">
            <v-col xl="2" lg="2" md="3" sm="5">
              {{ $t('datetime_page.current_ntp_server') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="currentNTPServer" />
            </v-col>
          </v-row>
          <v-row v-if="timeMode == 'ntp'">
            <v-col xl="2" lg="2" md="3" sm="5">
              {{ $t('datetime_page.time_is_synced') }}:
            </v-col>
            <v-col cols="auto">
              <span v-text="ntpSyncStatus ? $t('datetime_page.time_is_synced_yes') : $t('datetime_page.time_is_synced_no')" />
            </v-col>
          </v-row>
          
        </v-card-text>
      </v-card>
    </v-row>
    <v-form v-model="timeSettingsFormIsValid">
      <v-row class="d-flex justify-space-around py-5">
        <v-card
          v-if="timeSettingsIsEdit"
          width="85%"
          outlined
          elevation="4"
        >
          <v-card-text>
            <v-row align="center">
              <v-col cols="auto">
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('datetime_page.timezone') }}:
                  </v-list-item-title>
                </v-list-item>
              </v-col>
              <v-col xl="3" lg="4" md="4" sm="auto" cols="8">
                <v-autocomplete
                  class="v-card-text"
                  v-model="timeZoneEdited"
                  :items="timezones"
                  :error-messages="timeSettingsFormErrors.timezone"
                  hide-details="auto"
                  dense
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-row>
      <v-row class="d-flex justify-space-around py-5">
        <v-card
          v-if="timeSettingsIsEdit"
          width="85%"
          outlined
          elevation="4"
        >
          
          <v-subheader>
            <v-radio-group
              v-model="timeModeEdited"
              row
            >
              <v-radio
                :label="$t('datetime_page.radio_manual')"
                value="manual"
              ></v-radio>
              <v-radio
                :label="$t('datetime_page.radio_ntp_sync')"
                value="ntp"
              ></v-radio>
            </v-radio-group>
          </v-subheader>
          <v-divider></v-divider>
          <v-card-text>
            <v-row v-if="timeModeEdited === 'manual'" align="center">
              <v-col cols="auto">
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('datetime_page.system_date') }}:
                  </v-list-item-title>
                </v-list-item>
              </v-col>
              <v-col lg="2" md="3" sm="6" cols="8">
                <v-menu
                  ref="menu"
                  v-model="datePickerMenu"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  hide-details="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="date"
                    @input="datePickerMenu=false"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-row v-if="timeModeEdited === 'manual'" align="center">
              <v-col cols="auto">
                <v-list-item>
                  <v-list-item-title>
                    {{ $t('datetime_page.system_time') }}:
                  </v-list-item-title>
                </v-list-item>
              </v-col>
              <v-col lg="2" md="3" sm="6" cols="8">
                <v-text-field
                  v-model="time"
                  dense
                  full-width
                  hide-details
                  type='time'
                />
              </v-col>
              <v-spacer></v-spacer>
            </v-row>
            <v-list v-if="timeModeEdited === 'ntp'" >
              <v-list-item>
                <v-col xl="2" lg="3" md="4" sm="auto" cols="4">
                  <v-list-item-title >
                    {{ $t('datetime_page.primary_ntp_server') }}:
                  </v-list-item-title>
                </v-col>
                <v-col xl="3" lg="4" md="4" sm="auto" cols="8">
                  <v-text-field
                    v-model="ntpServers[0]"
                    @change="ntpServersWasEdited=true"
                    dense
                    required
                    hide-details
                  />
                </v-col>
              </v-list-item>
              <v-list-item>
                <v-col xl="2" lg="3" md="4" sm="auto" cols="4">
                  <v-list-item-title align="left">
                    {{ $t('datetime_page.secondary_ntp_server') }}:
                  </v-list-item-title>
                </v-col>
                <v-col xl="3" lg="4" md="4" sm="auto" cols="8">
                  <v-text-field
                    v-model="ntpServers[1]"
                    @change="ntpServersWasEdited=true"
                    dense
                    hide-details
                  />
                </v-col>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-row>
    </v-form>
    <v-row
      v-if="timeSettingsIsEdit"
      class="py-5"
    >
      <v-spacer></v-spacer>
      <v-btn
        color="primary--text"
        class="mb-2"
        @click="cancelTimeSettingsEditing"
      >
        {{ $t('datetime_page.cancel_button') }}
      </v-btn>
      <v-btn
        color="primary--text"
        class="mb-2"
        :disabled="applyTimeSettingsEditingIsDeny"
        @click="applyTimeSettingsEditing"
      >
        {{ $t('datetime_page.apply_button') }}
      </v-btn>
      <v-col cols=1></v-col>
    </v-row>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment-timezone';

export default {
  data: () => ({
    datePickerMenu: false,

    systemDate: null,
    systemTime: null,

    current_time: moment().format('MMMM Do YYYY, h:mm:ss a'),

    timeSettingsIsEdit: false,
    timeSettingsFormIsValid: false,

    timeSettingsFormErrors: {},
    timeSettingsFormErrorsDefault: {},

    timeModeEdited: '',
    timeZoneEdited: '',

    actual_time: undefined,

    ntpServers: [],
    ntpServersWasEdited: false,

    timezones: moment.tz.names(),
  }),
  computed: {
    date: {
      get () {
        return this.systemDate || this.actual_time.format('YYYY-MM-DD')
      },
      set (newDate) {
        this.systemDate = newDate
      }
    },
    time: {
      get () {
        return this.systemTime || this.actual_time.format('HH:mm')
      },
      set (newTime) {
        this.systemTime = newTime
      },
    },
    isAdministrator() {
      return this.$store.getters.isAdministrator
    },
    isLicenseValid() {
      return this.$store.getters['license/isLicenseValid']
    },
    ntpSyncStatus () {
      return this.$store.state.time.ntpSyncStatus
    },
    currentNTPServer () {
      return this.$store.state.time.currentNTPServer
    },
    serverTimeOffset() {
      return this.$store.state.time.serverTimeOffset
    },
    lightmasterTimezone() {
      return this.$store.state.time.lightmasterTimezone
    },
    actualTime() {
      return this.$store.getters['time/getActualTimeWithSeconds']
    },
    timeMode () {
      return this.$store.state.time.timeMode
    },
    applyTimeSettingsEditingIsDeny () {
      return !this.timeSettingsFormIsValid || !((this.timeZoneEdited != this.lightmasterTimezone || this.timeModeEdited != this.timeMode) || (this.systemDate || this.systemTime) || this.ntpServersWasEdited)
    }
  },
  methods: {
    editTimeSettings() {
      this.timeSettingsIsEdit = true
    },

    cancelTimeSettingsEditing () {
      this.timeModeEdited = this.$store.state.time.timeMode
      this.timeZoneEdited = this.$store.state.time.lightmasterTimezone

      this.systemDate = null
      this.systemTime = null

      this.ntpServersWasEdited = false

      this.timeSettingsIsEdit = false
      this.timeSettingsFormErrors = Object.assign({}, this.timeSettingsFormErrorsDefault)
    },

    applyTimeSettingsEditing () {
      let promises = []
      // System settings
      if (this.timeZoneEdited != this.lightmasterTimezone) {
        this.$store.dispatch('time/updateLightmasterTimezone', this.timeZoneEdited)
        .then(() => {
          moment.tz.setDefault(this.timeZoneEdited)
        })
      }
      if (this.timeModeEdited != this.timeMode) {
        this.$store.dispatch('time/updateTimeMode', this.timeModeEdited)
      }
      // Manual Time settings
      if (this.timeModeEdited == 'manual' && (this.systemDate || this.systemTime)) {
        let formatedTime = moment(`${this.date}T${this.time}`).toISOString()
        promises.push(
          this.$store.dispatch('time/setSystemTime', {'time': formatedTime})
          .catch(error => {
            console.log(error.response.data)
            this.timeSettingsFormErrors = Object.assign(this.timeSettingsFormErrors, error.response.data)
          })
        )
      }
      // Ntp servers
      if (this.timeModeEdited == 'ntp' && this.ntpServersWasEdited) {
        promises.push(
          this.$store.dispatch('time/setNTPServers', {'ntp_servers': this.ntpServers})
          .catch(error => {
            console.log(error.response.data)
            this.timeSettingsFormErrors = Object.assign(this.timeSettingsFormErrors, error.response.data)
          })
        )
      }

      Promise.all(promises).then(() => {
        this.cancelTimeSettingsEditing()
      })
    },
  },
  mounted () {
    
    this.$store.dispatch('time/getLightmasterTimezone').then(() => {
      this.timeZoneEdited = this.$store.state.time.lightmasterTimezone
      }
    )


    this.$store.dispatch('time/getTimeMode')
    .then(() => {
      this.timeModeEdited = this.$store.state.time.timeMode
      if (this.timeMode == 'ntp') {
        this.$store.dispatch('time/getNTPServers').then(() => {
          this.ntpServers = [...this.$store.state.time.ntpServers]
        })

        this.$store.dispatch('time/getNTPSyncStatus')
        this.$store.dispatch('time/getCurrentNTPServer')
      }
    })
  },
}
</script>